/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
/* eslint-disable operator-linebreak */
/**
 * 常用工具集
 */
import $dic from '@/api/dic.js'
import { Notification, MessageBox, Message } from 'element-ui'
var tool = {};

// 深拷贝
tool.extend = function (obj) {
  const ss = JSON.parse(JSON.stringify(obj));
  return ss;
};

// 倒计时器
/**
  * 倒计时器,该函数将返回一个countdown对象.
  * countdown对象拥有stop(),restart(),start(),done(callback),timeout()方法
  * 注意
  * 1.最后一次为0
  * 2.首次执行必定在延时之后
  * 3.二个参数必须为 number,不然会一直执行
  *
  * 参数
  * @param  {number} n        [倒数读秒]
  * @param  {number} interval [间隔]
  *
  * 示例
  * 无无限循环
   var ss = tool.Countdown(-1,1000).done(function (i) {
     console.log(i);
   });

  * 不立即执行
     var ss = tool.Countdown(10,1000).stop().done(function (i) {
     console.log(i);
   });

  * 特定条件停止
     var ss = tool.Countdown(10,1000).done(function (i) {
     console.log(i);
     if (i < 5) {
       this.stop();
     };
   });
  */
tool.countdown = function (n, interval) {
  interval = interval || 1000;
  var countdown = {
    idx: 0,
    max: n,
    i: n,
    callback: function () { },
    stop: function () {
      clearTimeout(this.idx);
      return this;
    },
    restart: function () {
      this.i = this.max;
      this.timeout();
      return this;
    },
    start: function () {
      this.timeout();
      return this;
    },
    timeout: function () {
      var self = this;
      self.idx = setTimeout(function () {
        // 当 tool.countdown(-1,1000) 时可以一直执行
        if (self.i === 0) {
          return self;
        }

        self.i--;
        self.timeout();
        self.callback.apply(countdown, [self.i]);
      }, interval);
      return self;
    },
    done: function (callback) {
      this.callback = callback;
      return this;
    },
  };
  countdown.timeout();
  return countdown;
};

// 快速排序 min-max
/**
 * 快速排序 min-max
 * by oTwo 2014年6月17日 13:33:29
 * @param  {array} d [description]
 * @return {array}
 *
 * ps:不改变原集合
 */
tool.quickSort = function (d) {
  if (d.length <= 1) {
    return d;
  }

  var k = d[0];
  var big = [];
  var small = [];
  var i;
  var len;
  for (i = 1, len = d.length; i < len; i++) {
    if (d[i] < k) {
      small.push(d[i]);
    } else {
      big.push(d[i]);
    }
  }
  small = tool.quickSort(small);
  big = tool.quickSort(big);

  return [].concat(small, k, big);
};

// 按KEY从小到大排序对象数组(JSON),不支持多层
/**
 * 按KEY从小到大排序对象数组(JSON),不支持多层
 * @param  {[{},{},{}]} d [对象数组(JSON)]
 * @param  {string} key  [对象中的键]
 * @return {JSON}      [description]
 */
tool.jsonSort = function (d, key) {
  if (d.length <= 1) {
    return d;
  }
  var k = d[0];
  var big = [];
  var small = [];
  var i;
  var len;
  for (i = 1, len = d.length; i < len; i++) {
    if (d[i][key] < k[key]) {
      small.push(d[i]);
    } else {
      big.push(d[i]);
    }
  }
  small = tool.jsonSort(small, key);
  big = tool.jsonSort(big, key);
  return [].concat(small, k, big);
};

// 生成指定范围内的随机整数
/**
 * 生成指定范围内的随机整数
 * by oTwo 2014年6月17日 13:33:35
 * @param  {number} begin [开始范围,默认 100, 可选]
 * @param  {number} end   [结束范围,默认 0, 可选]
 * @return {int}      [随机数]
 *
 * ps:把begin作为大数的原因是可以只写一个参数.roll(1000) === roll(1000,0)
 */
tool.roll = function (begin, end) {
  begin = isNaN(begin - 0) ? 100 : begin;
  end = isNaN(end - 0) ? 0 : end;
  if (begin < end) {
    var ss = begin;
    begin = end;
    end = ss;
  }

  var r = Math.random();
  r = r * (begin - end + 1) + end;
  r = parseInt(r, 10);
  return r;
};

// 取得地址档中GET的参数
/**
 * 取得地址档中GET的参数
 * @return {JSON} [description]
 */
tool.request = function () {
  var request = {};
  var hash = "";
  var ss;
  hash = window.location.search;
  hash = hash.substr(1);

  // 在这里返回 undefined 要比返回空对象好一点,取值我们更容易发现出了什么问题
  if (!hash) {
    return;
  }

  hash = decodeURIComponent(hash);
  hash = hash.split(/&/g);
  for (var f1 in hash) {
    ss = hash[f1].split(/=/g);
    request[ss[0]] = ss[1];
  }
  return request;
};

// 格式化成时间字符串
/**
 * 格式化成时间字符串 简版,依赖 tool.pad
 * @param  {date/str/number} date   [时间对像,或能转为时间对像字符串/数字]
 * @param  {str} format ['Y年M月D日 hh:mm:ss']
 * @return {str}
 */
tool.formatDate = function (date, format) {
  var d = new Date(date);
  var map = {
    Y: d.getFullYear(),
    M: d.getMonth() + 1,
    D: d.getDate(),
    hh: d.getHours(),
    mm: d.getMinutes(),
    ss: d.getSeconds(),
  };

  // 从左边补齐二位
  for (var f2 in map) {
    map[f2] = map[f2].toString().padStart(2, "0");
  }

  for (var f1 in map) {
    format = format.replace(f1, map[f1]);
  }
  return format;
};

// 通过id搜索数据
tool.findData = function (d, id, key) {
  key = key || "id";
  for (var i = 0, len = d.length; i < len; i++) {
    if (d[i][key] === id) {
      return d[i];
    }
  }
  return null;
};

tool.isPC = function () {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};

tool.isIOS = function () {
  var u = navigator.userAgent;
  var isIOS = Boolean(u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/));
  return isIOS;
};

/**
 * 判断案例库的平台是否为微信
 * @author gaolei6
 * @time 2016-09-18
 * @return String
 */
tool.isWx = function () {
  var ua = navigator.userAgent;
  var pattern_equipent = /MicroMessenger/;
  if (pattern_equipent.test(ua)) {
    // 微信客户端
    return true;
  }

  return false;
};

/**
 * 判断案例库的平台是否为微博
 * @author gaolei6
 * @time 2016-09-18
 * @return String
 */
tool.isWb = function () {
  var ua = navigator.userAgent;
  var pattern_weibo = /weibo/;
  if (pattern_weibo.test(ua)) {
    // 微博客户端
    return true;
  }
  return false;
};

/**
 * 比较两个obj是否相同
 * @author gaolei6
 * @time 2016-09-18
 * @param obj 被比较的对象
 * @param obj 参考比较的对象
 * @return Bollean
 */
tool.cmpObj = function (x, y) {
  // If both x and y are null or undefined and exactly the same
  if (x === y) {
    return true;
  }

  // If they are not strictly equal, they both need to be Objects
  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }

  // They must have the exact same prototype chain,the closest we can do is
  // test the constructor.
  if (x.constructor !== y.constructor) {
    return false;
  }

  for (var p in x) {
    // Inherited properties were tested using x.constructor === y.constructor
    if (x.hasOwnProperty(p)) {
      // Allows comparing x[ p ] and y[ p ] when set to undefined
      if (!y.hasOwnProperty(p)) {
        return false;
      }

      // If they have the same strict value or identity then they are equal
      if (typeof x[p] === "object" && typeof y[p] === "object") {
        if (cmpObj(x[p], y[p])) {
          continue;
        } else {
          return false;
        }
      }

      if (x[p] !== y[p]) {
        return false;
      }
    }
  }

  for (p in y) {
    // allows x[ p ] to be set to undefined
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
};

// 进入全屏
tool.requestFullScreen = function () {
  var de = document.documentElement;
  if (de.requestFullscreen) {
    de.requestFullscreen();
  } else if (de.mozRequestFullScreen) {
    de.mozRequestFullScreen();
  } else if (de.webkitRequestFullScreen) {
    de.webkitRequestFullScreen();
  }
};

// 退出全屏
tool.exitFullscreen = function () {
  var de = document;
  if (de.exitFullscreen) {
    de.exitFullscreen();
  } else if (de.mozCancelFullScreen) {
    de.mozCancelFullScreen();
  } else if (de.webkitCancelFullScreen) {
    de.webkitCancelFullScreen();
  }
};

/**
 * 1 所有可以转为 false 的值 都是"空".
 * 2 深度检测对象,如果对象的所有值都是"空"则该对象是"空"
 *
 * by oTwo 2016年10月25日 11:54:57
 * @return {Boolean}   [description]
 */
tool.isEmpty = function (d) {
  if (typeof d !== "object") {
    return !d;
  }
  var ss = true;
  var f1;
  for (f1 in d) {
    if (typeof d[f1] === "object") {
      ss = tool.isEmpty(d[f1]);
    } else {
      ss = !d[f1];
    }

    if (ss === false) {
      return false;
    }
  }
  return true;
};

tool.isURL = function (str_url) {
  var strRegex = `^((https|http|ftp|rtsp|mms)?://)?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-z_!~*'()-]+.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].[a-z]{2,6})(:[0-9]{1,4})?((/?)|(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$`;
  var re = new RegExp(strRegex);

  // re.test()
  if (re.test(str_url)) {
    return true;
  } else {
    return false;
  }
};

// 是否为验证码
tool.isCode = function (code) {
  var reg = /^[\d]{6}$/;
  return reg.test(code);
};

// 是否为手机
tool.isPhone = function (phone) {
  var reg = /^1[2345789]\d{9}$/;
  return reg.test(phone);
};

// 是否为邮箱
tool.isEmail = function (email) {
  var reg =
    /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  return reg.test(email);
};

// 验证身份证信息
tool.isCert = function (val) {
  const reg = new RegExp(
    /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  );

  const card = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const cardX = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
  let last = 0;
  let sum = 0;
  if (!reg.test(val)) {
    return false;
  }

  // 校验位识别
  for (let i = 0, l = val.length; i < l; i++) {
    if (i < val.length - 1) {
      sum += val[i] * card[i];
    }
    if (i === val.length - 1) {
      last = val[i];
    }
  }
  return cardX[sum % 11] === last;
};

// 统一社会信用代码
tool.isCreditCode = function (val) {
  const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10})$/;
  return reg.test(val);
};

// 表单校验
//  { trigger: 'blur',message:'统一社会信用代码校验错误' ,validator: this.$tool.validateCreditCode, }
tool.validateCreditCode = (rule, value, callback) => {
  if (tool.isCreditCode(value)) {
    callback();
  } else {
    callback(new Error(rule.message));
  }
};

// 输入字符类型只能包含字母，数字以及英文半角标点符号（除空格），长度限制1~16
tool.str16 = (rule, value, callback) => {
  let reg = new RegExp("^[A-Za-z0-9~!@#$%^&*()-_=+<>,.:;\\|'\"]{1,16}$");

  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error(rule.message));
  }
};

// 输入字符类型只能包含字母，数字以及英文半角标点符号（除空格），长度限制1~32
tool.str32 = (rule, value, callback) => {
  let reg = new RegExp("^[A-Za-z0-9~!@#$%^&*()-_=+<>,.:;\\|'\"]{1,32}$");
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error(rule.message));
  }
};

// 输入字符类型只能包含字母，数字以及英文半角标点符号（除空格），长度限制1~36
tool.str36 = (rule, value, callback) => {
  let reg = new RegExp("^[A-Za-z0-9~!@#$%^&*()-_=+<>,.:;\\|'\"]{1,36}$");
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error(rule.message));
  }
};

// 非法字符
tool.str500 = (rule, value, callback) => {
  let reg = new RegExp(
    "[\\\\`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]{1,500}"
  );
  if (!reg.test(value)) {
    callback();
  } else {
    callback(new Error(rule.message));
  }
};

// 表单校验 用户名
tool.validateUsername = (rule, value, callback) => {
  let reg = new RegExp("[A-Za-z0-9_]{4,16}");
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error(rule.message));
  }
};

// 将 Byte 转为 K/m等 单位
tool.toKB = function (int) {
  int = int / 1024;
  if (int < 1024) {
    return `${Math.ceil(int)} K`;
  }

  int = int / 1024;
  if (int < 1024) {
    return `${Math.ceil(int)} M`;
  }
};

// 整数 千分位逗号
tool.toThousands = function (num) {
  if (!num) {
    return 0;
  }
  if (isNaN(num - 0)) {
    return num;
  }

  const ss = num.toString().split(".");
  let str = ss[0].toString().replace(/^-?\d+/g, (m) => {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ",");
  });
  if (ss[1]) {
    str += `.${ss[1]}`;
  }
  return str;
};
tool.toThousandsTable = function (row, column, cellValue, index) {
  cellValue = cellValue - 0;
  if (isNaN(cellValue)) {
    return "0.00";
  }
  if (cellValue === 0) {
    return "0.00";
  }
  cellValue = cellValue.toFixed(2);
  const ss = cellValue.toString().split(".");
  let str = ss[0].toString().replace(/^-?\d+/g, (m) => {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ",");
  });
  if (ss[1]) {
    str += `.${ss[1]}`;
  }
  return str;
};

tool.toThousandsTable2 = function (row, column, cellValue, index) {
  cellValue = cellValue - 0;
  if (isNaN(cellValue)) {
    return "0";
  }
  if (cellValue === 0) {
    return "0";
  }
  const ss = cellValue.toString().split(".");
  let str = ss[0].toString().replace(/^-?\d+/g, (m) => {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ",");
  });
  if (ss[1]) {
    str += `.${ss[1]}`;
  }
  return str;
};

// 将数组转为树
// tool.arrToTree(res.data, {id: 'id', pid: 'pid', children: 'children'})
// 返回的是个数组,数组其中的对象为树结构
tool.arrToTree = function (data, config = {}) {
  var id = config.id || "id";
  var pid = config.pid || "pid";
  var children = config.children || "children";
  var idMap = {};
  var jsonTree = [];
  data.forEach(function (v) {
    idMap[v[id]] = v;
  });
  data.forEach(function (v) {
    var parent = idMap[v[pid]];
    if (parent) {
      !parent[children] && (parent[children] = []);
      parent[children].push(v);
    } else {
      jsonTree.push(v);
    }
  });
  return jsonTree;
};

// 将树转为数组
// tool.treeToArr(res.data)
// tool.treeToArr(res.data,{ children: 'children' })
// 返回的是个二组数组
tool.treeToArr = function (data, config = { children: "children" }, pid) {
  data = tool.extend(data);
  var arr = [];
  data.forEach(function (item) {
    let d = tool.extend(item);
    let children = item[config.children];
    delete d[config.children];
    d[config.pid] = pid;
    arr.push(d);

    if (children && Array.isArray(children)) {
      arr = arr.concat(tool.treeToArr(children, config, item.id));
    }
  });
  return arr;
};

// 将数组转为对象
tool.arrToObj = function (data, key) {
  let ss = [];
  for (let item of data) {
    ss[key] = item[key];
  }
  return ss;
};

tool.formatterName = function (row, column, cellValue, index) {
  var ss = cellValue.split("");
  if (ss.length < 3) {
    ss = `${ss[0]}*`;
  } else {
    ss = `${ss[0]}*${ss[ss.length - 1]}`;
  }

  return ss;
};

tool.formatterDate = function (row, column, cellValue, index) {
  var ss = tool.formatDate(cellValue, "Y-M-D");
  return ss;
};

//字典过滤
tool.dicFilter = function (val, list, label, value) {
  let text = "";
  let isLabel = label ? label : "label";
  let isValue = value ? value : "value";
  list.forEach((item) => {
    if (item[isValue] == val) {
      text = item[isLabel];
    }
  });
  return text;
};

tool.treeFilter = function (arr, list, icon, key) {
  let text = "";
  let isIcon = icon ? icon : " / ";
  let val = tool.treeToArr(list, { children: "children" });
  let isArr = JSON.parse(arr);
  if (Object.prototype.toString.call(isArr) === '[object Array]') {
    isArr.forEach((item) => {
      val.forEach((j) => {
        if (item == j.id) {
          if (key) {
            text += j[key] + isIcon;
          } else {
            text += j.name + isIcon;
          }

        }
      });
    });
    text = text.substring(0, text.length - 2);
  }
  return text;
};


tool.treeFilterLength = function (arr, list, icon, key) {
  let text = "";
  let isIcon = icon ? icon : " / ";
  let val = tool.treeToArr(list, { children: "children" });
  let isArr = JSON.parse(arr);
  if (Object.prototype.toString.call(isArr) === '[object Array]') {
    isArr.forEach((item) => {
      val.forEach((j) => {
        if (item == j.id) {
          if (key) {
            text += j[key] + isIcon;
          } else {
            text += j.name + isIcon;
          }

        }
      });
    });
    text = text.substring(0, text.length - 1);
  }
  return text;
};

// echart
tool.color = ["#3C74FF", "#3AC27E", "#FB8231", "#d48265", "#749f83"];

// 计算每个item的value占所有value总和的百分比，并返回一个新的数组
// 增加百分比数据 增加百分比数据 item.percent
// val = this.$tool.calculateValuePercentage(val);
tool.calculateValuePercentage = function (data) {
  // 计算value总和,当总和为0时,设置totalValue = 1
  const totalValue = data.reduce((acc, curr) => acc + curr.value, 0) || 1;

  // 使用Intl.NumberFormat（支持ES6+环境）
  const formatNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "percent",
  });

  // 计算每个item的value百分比并更新数据
  const updatedData = data.map((item) => ({
    ...item,
    percent: formatNumber.format(item.value / totalValue) || 0,
  }));

  return updatedData;
};

// $tool.percentStr(data)
tool.percentStr = function (v) {
  if (isNaN(v - 0)) {
    return "0%";
  }
  // 使用Intl.NumberFormat（支持ES6+环境）
  const formatNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "percent",
  });
  return formatNumber.format(v);
};

// 如果小数位没有值,则不显示小数位
tool.percentStr2 = function (v) {
  if (isNaN(v - 0)) {
    return "0%";
  }
  // 使用Intl.NumberFormat（支持ES6+环境）
  const formatNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "percent",
  });
  let ss = formatNumber.format(v);
  ss = ss.replace('.00', '')
  return ss;
};

// 按key 对数组重新分组 有排序
tool.groupDataByKey = function (inputData, key = "key") {
  // 创建一个对象用于存放分组后的结果
  const groupedData = {};

  // 遍历输入数据
  inputData.forEach((item) => {
    // 如果对象中还没有对应key的数组，则创建一个新数组
    if (!groupedData[item[key]]) {
      groupedData[item[key]] = [];
    }
    // 将当前对象添加到对应key的数组中
    groupedData[item[key]].push(item);
  });

  // 将分组后的对象转换为数组，并按key排序
  const outputData = Object.entries(groupedData)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map((entry) => entry[1]);

  return outputData;
};

// 按labelName 对数组重新分组 ,无排序
tool.groupDataByName = function (input, key = "labelName") {
  let output = {};

  input.forEach((item) => {
    if (!output[item[key]]) {
      output[item[key]] = [];
    }
    output[item[key]].push({
      value: item["labelValue"],
      name: item["labelName"],
      key: item["key"],
      ...item,
    });
  });

  return Object.values(output);
};

// 按labelName 对数组重新分组 ,无排序
tool.transformPostgraduate = function (inputArray) {
  const outputArray = [];

  const groupedData = inputArray.reduce((acc, curr) => {
    if (curr.labelName === '本科') {
      const key = `${curr.key}-${curr.labelName}`;
      if (!acc[key]) {
        acc[key] = { ...curr };
      } else {
        acc[key].labelValue += curr.labelValue;
      }
    } else if (curr.labelName === '硕士' || curr.labelName === '博士') {
      const key = `${curr.key}-研究生`;
      if (!acc[key]) {
        acc[key] = { ...curr, labelName: '研究生' };
      } else {
        acc[key].labelValue += curr.labelValue;
      }
    }
    return acc;
  }, {});

  for (const key in groupedData) {
    outputArray.push(groupedData[key]);
  }

  return outputArray;
};

// 转为echarts用的 Dataset
tool.toDataset = function (input) {
  let output = [["product"]];
  let years = new Set();

  // 提取所有年份
  input.forEach((item) => {
    years.add(item.key);
  });

  // 将年份按升序排列
  years = Array.from(years).sort();

  output[0] = output[0].concat(years);

  // 构建输出数据
  let dataMap = {};
  input.forEach((item) => {
    if (!dataMap[item.labelName]) {
      dataMap[item.labelName] = new Array(output[0].length).fill(0);
      dataMap[item.labelName][0] = item.labelName;
    }
    dataMap[item.labelName][output[0].indexOf(item.key)] = item.labelValue;
  });

  // 将数据转换为数组形式
  Object.values(dataMap).forEach((value) => {
    output.push(value);
  });

  return output;
};

// 使用key 依据sortArr将JSON重排序
// sortedJsonData = sortData(jsonData, sortArr);
tool.sortData = function (data, sortArr = ["本科", "硕士", "博士"], key = 'key') {
  let sortedData = [];
  sortArr.forEach((item) => {
    let filteredData = data.filter((obj) => obj[key] === item);
    sortedData = sortedData.concat(filteredData);
  });
  return sortedData;
};

// this.$tool.clearSeriesData(this.myChart)
tool.clearSeriesData = function (chartInstance) {
  // 获取当前图表的完整配置
  let option = chartInstance.getOption();

  const seriesCfg = []
  // 遍历并清空所有系列的数据
  option.series.forEach((series) => {
    seriesCfg.push({
      data: []
    })
  });

  // 设置新的option来清除数据
  chartInstance.setOption({ series: seriesCfg });
};
tool.download = function (data, titName) {
  if (!data) {
    return
  }
  const content = data
  const blob = new Blob([content], { type: 'application/vnd.ms-excel' })
  const fileName = titName || ''

  // 非IE下载
  if ('download' in document.createElement('a')) {
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()

    // 释放URL 对象
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)

    // IE10+下载
  } else {
    navigator.msSaveBlob(blob, fileName)
  }
  Message.success('导出成功!')
}


/**
 * 为列表中的每个项目添加一个“level”属性，表示其在树中的层级。
 * @param {Array} list - 要添加“level”属性的项目列表。每个项目应包含"id"和"pid"属性，分别表示项目的唯一标识和父项目的标识。
 * @returns {Array} - 带有“level”属性的项目列表。列表中的每个项目将新增一个“level”属性，指示该项目在树结构中的深度。
 *
 * 示例：
 * ```javascript
 * const list = [{id:1,pid:0},{id:2,pid:1}];
 * const listWithLevel = addLevel(list);
 * console.log(listWithLevel);
 * // 输出: [{id: 1, pid: 0, level: 1}, {id: 2, pid: 1, level: 2}]
 * res.data = this.$tool.addLevel(res.data);
 * ```
 */
tool.addLevel = function (list) {
  // 使用Map存储列表项目，以便通过id高效访问
  const map = new Map();

  // 将列表中的项目添加到map，以id为键
  list.forEach(item => { map.set(item.id, item); });

  // 遍历列表，为每个项目计算并设置level
  list.forEach(item => {
    let level = 1; let parentId = item.pid;

    // 通过循环查找到项目的顶级父项目，计算项目的层级level
    while (parentId !== 0 && map.has(parentId)) {
      level++;
      parentId = map.get(parentId).pid;
    }

    // 设置项目的层级level
    item.level = level;
  });

  // 返回带有level属性的项目列表
  return list;
}

/**
 * 遍历树形数据结构的广度优先遍历（BFS）迭代器函数。
 *
 * @param {Object} tree - 树形数据结构的对象。每个节点应具有以下属性：
 *   - value: 节点的值。
 *   - children: 一个数组，包含该节点的所有直接子节点（可选）。
 *
 * @param {Function} callback - 处理每个节点的回调函数。接收一个参数：
 *   - item: 当前遍历到的节点对象。
 *
 * @example
 * const tree = {
 *   value: "A",
 *   children: [
 *     { value: "B", children: [{ value: "D" }, { value: "E" }] },
 *     { value: "C", children: [{ value: "F" }, { value: "G" }] }
 *   ]
 * };
 *
 * function logNode(node) {
 *   console.log(node.value);
 * }
 *
 * traverseTree(tree, logNode);
 */
tool.traverseTree = function (tree, callback) {
  const queue = [tree];

  while (queue.length > 0) {
    const current = queue.shift();

    callback(current);

    if (current.children) {
      queue.push(...current.children);
    }
  }
}

/**
 * 通用处理函数，处理数组中的对象的null值，
 * 根据提供的映射规则创建一个处理后的数组深副本。
 * @param {Array<Object>} inputArray - 需要处理的对象数组。
 * @param {Object} map - 映射规则对象，键为对象中的属性名，值为该属性的默认值。若为空，处理所有属性的null值。
 * @returns {Array<Object>} - 处理后的对象数组深副本。
 * @example
 *
 * // 原始数据
 * const employees = [
 *   { id: 1, name: 'Alice', age: null, department: 'Sales' },
 *   { id: 2, name: 'Bob', age: 30, department: null },
 *   { id: 3, name: 'Charlie', age: null, department: null },
 * ];
 *
 * // 映射规则
 * const defaultValues = {
 *   age: 0,
 *   department: 'Unknown',
 * };
 *
 * // 使用processArrayWithMap函数
 * const processedEmployees = processArrayWithMap(employees, defaultValues);
 *
 * console.log(processedEmployees);
 * // 输出:
 * // [
 * //   { id: 1, name: 'Alice', age: 0, department: 'Sales' },
 * //   { id: 2, name: 'Bob', age: 30, department: 'Unknown' },
 * //   { id: 3, name: 'Charlie', age: 0, department: 'Unknown' },
 * // ]
 */

/*
const defaultValues = {
  thisYearEnrollment: 0,
  lastYearEnrollment: 0,
  beforYearEnrollment: 0,
};
const list = this.$tool.processArrayWithMap(res.rows, defaultValues);
 */
tool.processArrayWithMap = function (inputArray, map) {
  const newArray = JSON.parse(JSON.stringify(inputArray));

  if (!map) {
    newArray.forEach(item => {
      Object.entries(item).forEach(([key, value]) => {
        if (value === null) item[key] = 0;
      });
    });
  } else {
    newArray.forEach(item => {
      Object.keys(map).forEach(key => {
        if (item[key] === null) item[key] = map[key];
      });
    });
  }

  return newArray;
}
tool.toNumber = function (v) {
  v = v - 0
  if (isNaN(v)) {
    return ''
  }
  return Number((v).toFixed(2))
}
tool.sortByIndex = function(list){
const sortedList = [...list];  
  
sortedList.sort((a, b) => {  
    if (a.isTop == '0' && b.isTop == '0') {  
        return a.orderIndex - b.orderIndex;  
    }  
    if (a.isTop == '0') {  
        return -1;  
    }    
    if (b.isTop == '0') {  
        return 1;  
    }  
    return a.orderIndex - b.orderIndex;  
    });  

  return sortedList;  
}

export default tool;
