import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import VideoPlayer from 'vue-video-player'
import Pagination from "@/components/Pagination";

import 'video.js/dist/video-js.css'

import 'vue-video-player/src/custom-theme.css'

import 'element-ui/lib/theme-chalk/display.css';
import {loadDic} from "@/api/dic";
import tool from "@/utils/tool.js";

// global css
import '@/assets/styles/index.scss'
import '@/assets/styles/quill.scss'

Vue.component('Pagination', Pagination)

Vue.use(VideoPlayer)
Vue.use(Element)

Vue.config.productionTip = false

Vue.prototype.loadDic = loadDic
Vue.prototype.$tool = tool;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
