import request from '@/utils/request'
// 首页数据
export function getHome(data){
    return request({
        url:'/web/menu/get/one',
        method: 'get',
        params:data
    })
}
//菜单
export function getAll(){
    return request({
        url:'/web/menu/getAll',
        method: 'get',
    })
}
//学院概述-学院介绍&机构设置
export function getContent(data){
    return request({
        url:'/web/situation/setting/getAll',
        method: 'get',
        params:data
    })
}
//banner
export function getBanner(){
    return request({
        url:'/web/portal/banner/getAll',
        method: 'get'
    })
}
//首页-学院管理
export function getCollege(){
    return request({
        url:'/web/portal/college/get/all',
        method: 'get'
    })
}
export function getCollegeById(data){
    return request({
        url:'/web/portal/college/get/one',
        method:'get',
        params:data
    })
}
export function getCollegeChild(data){
    return request({
        url:'/web/portal/college/child/list',
        method:'get',
        params:data
    })
}
export function getCollegeChildById(data){
    return request({
        url:'/web/portal/college/child/get/one',
        method:'get',
        params:data
    })
}
//首页-校园资讯
export function getNews(data){
    return request({
        url:'/web/consult/setting/getAll',
        method: 'get',
        params:data
    })
}
export function getNewsList(data){
    return request({
        url:'/web/consult/setting/list',
        method: 'get',
        params:data
    })
}
export function getNewsById(data){
    return request({
        url:'/web/consult/setting/get/one',
        method: 'get',
        params:data
    })
}

//学院视频
export function getVideo(){
    return request({
        url:'/web/portal/college/video/get/all',
        method: 'get'
    })
}
//企业swiper
export function getCoop(){
    return request({
        url:'/web/portal/company/getAll',
        method: 'get'
    })
}
//推荐阅读
export function getRead(){
    return request({
        url:'/web/portal/read/getAll',
        method: 'get'
    })
}

//内容编辑 get
export function getContentById(data){
    return request({
        url:'/web/portal/content/get/one',
        method: 'get',
        params:data
    })
}
//内容编辑 保存
export function saveContent(data){
    return request({
        url:'/web/portal/content/saveOrUpdate',
        method: 'post',
        data
    })
}