import request from "@/utils/request";
import $tool from "@/utils/tool.js";

// 加载字典
export function loadDic(data) {
  return request({
    url: `/system/dict/data/type/${data}`,
    method: "get",
  });
}
export function loadSpecialDic(data) {
  return request({
    url: `/common/menu/getList?type=${data}`,
    method: "get",
  });
}