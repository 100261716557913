<template>
  <div class="head_box">
    <el-row class="top_logo hidden-md-and-down">
      <el-col :md="4" align="left" class="hidden-sm-and-down"
        ><img src="@/assets/images/logo.png" alt=""
      /></el-col>
      <el-col :lg="20">
        <el-row class="top_menu hidden-sm-and-down">
          <el-col :span="24">
            <div class="menu clearfix fl">
              <ul>
                <li
                  v-for="(item, index) in topnavTree"
                  :key="index"
                  @click="handleClick(item.menuName)"
                  :class="{ active: isActive(item) }"
                >
                  <div class="item">
                    {{ item.menuName }}
                  </div>
                  <!-- <router-link :to="{name:item.menuName}" tag="div" class="item">{{ item.menuName }}</router-link> -->
                  <ul class="subMenu" v-if="item.children">
                    <li
                      v-for="(subItem, subIndex) in item.children"
                      :key="subIndex"
                      @click="
                        handleClick(subItem.menuName, $event, item.menuName)
                      "
                      :class="{ active: isActive(subItem) }"
                    >
                      <!-- <router-link :to="{name:subItem.menuName}" tag="div" class="submenu_name">{{ subItem.menuName }}</router-link> -->
                      <!--  -->
                      <div class="submenu_name">
                        {{ subItem.menuName }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <!-- <el-col :span="4" :offset="11">
        <p class="p_right">
          <span>联系我们</span>
          <span>0451-86684766</span>
        </p>
      </el-col>
      <el-col :span="4">
        <p class="p_right">
          <span>招生电话</span>
          <span>0451-86631888</span>
        </p>
      </el-col> -->
    </el-row>
    <!-- <el-row class="top_menu hidden-sm-and-down">
      <el-col :span="24">
        <div class="menu clearfix fl">
          <ul>
            <li
              v-for="(item, index) in topnavTree"
              :key="index"
              @click="handleClick(item.menuName)"
              :class="{ active: isActive(item)}"
            >
              <div
                class="item"
              >
                {{ item.menuName}}
              </div>

              <ul class="subMenu" v-if="item.children">
                <li
                  v-for="(subItem, subIndex) in item.children"
                  :key="subIndex"
                  @click="handleClick(subItem.menuName, $event, item.menuName)"
                  :class="{ active: isActive(subItem)}"
                >
                  <div
                    class="submenu_name"
                  >
                    {{ subItem.menuName}}
                  </div>
                </li>
              </ul>
            </li>
        </ul>
        </div>

      </el-col>
    </el-row> -->
    <!-- 移动端 菜单 -->
    <el-row class="hd_menu">
      <div style="height: 80px; position: relative">
        <el-row
          type="flex"
          align="middle"
          style="height: 100%; padding: 0 20px"
          class="mobile-nav"
        >
          <el-col :xs="20" style="text-align: left"
            ><img
              src="@/assets/images/logo.png"
              alt=""
              width="185px"
              height="38px"
          /></el-col>
          <el-col :xs="4" :lg="4" align="right">
            <img
              src="@/assets/images/phone/more.png"
              alt=""
              @click="handleClickShow"
            />
          </el-col>
        </el-row>

        <el-drawer
          title="导航"
          :visible.sync="drawer"
          :append-to-body="true"
          class="drawer-box"
        >
          <div class="drawer">
            <el-collapse v-model="activeName" accordion @change="handleClick3">
              <el-collapse-item
                :title="lv1.menuName"
                :name="lv1.menuName"
                class="lv-1"
                v-for="(lv1, idx) in topnavTree"
                :key="idx"
              >
                <div class="lv-1">
                  <div
                    class="lv-2"
                    v-for="(lv2, idx) in lv1.children"
                    :key="idx"
                  >
                    <div class="lv-2-name" @click="handleClick2(lv2.menuName)">
                      {{ lv2.menuName }}
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-drawer>
      </div>
    </el-row>
  </div>
</template>

<script>
  import "element-ui/lib/theme-chalk/display.css"
  import { getAll } from "@/api/home"
  export default {
    name: "topNav",
    data() {
      return {
        drawer: false,
        activeName: "",

        activeIndex: "1",
        dic: "school_info",
        topnavTree: [],
        activeMenu: null,
        isEducation: "教育教学",
        educSubMenu: [],
        menuItems: []
      }
    },
    mounted() {},
    created() {
      this.getList()
    },
    methods: {
      isActive(item) {
        const currentName = this.$route.name
        const checkMatch = (current, target) => {
          if (current === target.menuName) {
            return true
          }
          if (target.children) {
            for (const child of target.children) {
              if (checkMatch(current, child)) {
                return true
              }
            }
          }
          return false
        }
        return checkMatch(currentName, item)
      },
      handleClick(name, event, parentName) {
        if (name == "学院概况") {
          name = "学院介绍"
        }
        this.$store.commit("setNavData", name)
        if (event) {
          event.stopPropagation() // 阻止事件冒泡
        }

        let activeMenuName = name
        this.activeMenu = activeMenuName

        // 学院概况 相关连接
        if (name === "现任领导") {
          this.$router.push({
            name: "学院介绍",
            query: { tab: "1" }
          })
          return
        }
        if (name === "机构设置") {
          this.$router.push({
            name: "学院介绍",
            query: { tab: "2" }
          })
          return
        }
        if (name === "校园资讯") {
          this.$router.push({
            name: "学院介绍",
            query: { tab: "3" }
          })
          return
        }
        if (name === "校园风貌") {
          this.$router.push({
            name: "学院介绍",
            query: { tab: "4" }
          })
          return
        }

        if (name === "学习园地") {
          this.$router.push({
            name: "党群工作",
            query: { tab: "0" }
          })
          return
        }
        if (name === "龙旅讲堂") {
          this.$router.push({
            name: "党群工作",
            query: { tab: "1" }
          })
          return
        }
        if (name === "党群动态") {
          this.$router.push({
            name: "党群工作",
            query: { tab: "2" }
          })
          return
        }
        if (name === "文明校园") {
          this.$router.push({
            name: "党群工作",
            query: { tab: "3" }
          })
          return
        }
        if (name === "校企合作") {
          this.activeMenu = "1"
          window.open("https://ljlyzy.gxbys.org.cn/", "_blank")
          this.$router.go(0)
          return
        }
        if (name === "乡村振兴") {
          this.$router.push({
            path: "/countrySide",
            query: {
              name: "乡村振兴"
            }
          })
          return
        }
        this.$router.push({
          name
        })
      },

      // 移动端菜单点击
      handleClick2(name) {
        this.drawer = false
        this.handleClick(name, event)
      },
      handleClick3(name, event, parentName) {
        if (name === "学院概况" || name === "党群工作" || name === "教育教学") {
          this.drawer = true
        } else {
          this.drawer = false

          setTimeout(() => {
            this.activeName = ""
          }, 300)

          this.handleClick(name, event, parentName)
        }
      },
      handleSelect(key, keyPath) {},
      getList() {
        getAll().then((res) => {
          //保存导航信息
          this.$store.commit("SET_TOPNAV", res.data.data)

          this.topnavTree = this.$tool.arrToTree(res.data.data, {
            id: "id",
            pid: "pid",
            children: "children"
          })
          this.topnavTree = this.$tool.sortByIndex(this.topnavTree)
          this.topnavTree.forEach((item) => {
            if (item.children) {
              item.children = this.$tool.sortByIndex(item.children)
            }
          })
        })
      },
      handleClickShow() {
        // this.$refs.fixed_bg.style.display = "block"
        this.drawer = true
      },
      pHandleSelect(key, keyPath) {}
    }
  }
</script>

<style scoped lang="scss">
  .el-menu-item {
    padding: 15px 14px;
    color: #383838;
  }
  .head_box {
    width: calc(100% - 30px);
    height: 134px;
    padding: 0 15px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 1;
    /* min-width: 1380px; */
    z-index: 999;
    transition: 0.6s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top_logo {
      width: 100%;
      // padding: 50px 0 30px 0;
      .p {
        font-size: 18px;
        color: #2e2e2e;
      }
      .p_right {
        margin: 0;
        span {
          color: #0562f9;
          display: block;
        }
      }
      .p_right span:last-child {
        font-size: 28px;
        margin-top: 5px;
      }
    }
    .top_menu {
      position: absolute;
      bottom: 10px;
      .el-menu--horizontal > .el-menu-item {
        height: auto;
        line-height: normal;
        font-size: 20px;
        color: #383838;
      }
      .el-menu--horizontal > .el-menu-item:hover {
        color: #fff !important;
        background-color: #0562f9 !important;
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        color: #fff !important;
        background-color: #0562f9 !important;
      }
    }
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item {
    border-bottom: none !important;
  }
  .topnav {
    background-color: #fff;
    height: 100%;
    /* width: 1360px; */
    margin: 0 auto;
    position: relative;
  }
  .left_logo {
    float: left;
    margin-top: 32px;
    width: 250px;
  }

  .menu {
    // padding-top: 46px;
    color: #383838;
    margin-left: 10px;
    position: relative;
    font-size: 20px;
  }
  .menu .item {
    display: block;
    text-decoration: none;
    color: #383838;
    cursor: pointer;
  }
  .menu > ul {
    list-style: none;
  }
  .menu > ul > li {
    position: relative;
    float: left;
    cursor: pointer;
    padding: 15px 14px;
  }
  .menu > ul > li:first-child {
    background-image: none;
    text-align: center;
  }
  .menu > ul > li:first-child:hover {
    background-color: #0562f9;
  }
  /* .menu > ul > li:hover:nth-child(8)::after{
      min-width: 80px;
      left: 0;
  } */
  .menu > ul > li > .item {
    position: relative;
  }
  .menu > ul > li:hover {
    background-color: #0562f9;
    .item {
      color: #fff;
    }
  }
  .menu > ul > li.active {
    background-color: #0562f9;
    .item {
      color: #fff;
    }
  }
  .menu > ul > li:hover > .item:last-child::after {
    width: 100%;
  }
  .menu > ul > li > .item {
    display: inline-block;
    width: auto;
  }
  .menu > ul > li > .el-menu-item.is-active {
    background-color: #0562f9;
  }
  .menu > ul > li:first-child > .active::after {
    min-width: 40px;
  }
  .menu > ul > li:hover .subMenu {
    z-index: 10;
    height: auto;
    min-height: 130px;
    max-height: 280px;
  }
  .menu > ul > li:hover .subMenu li {
    transform: rotateY(0deg);
    opacity: 1;
  }
  .menu > ul > li:first-child .subMenu {
    left: -50px;
  }
  .subMenu {
    max-height: 0;
    position: absolute;
    // left: -14px;
    left: -20px;
    top: 55px;
    z-index: 0;
    padding: 0;
    width: calc(100% + 40px);
    border-radius: 2px;
    box-shadow: 0px 0px 12px 0px rgba(168, 168, 168, 0.46);
    text-align: center;
    background: #fff;
    transition: all 0.5s;
  }
  .subMenu li {
    padding: 10px 14px;
    opacity: 0;
    transform: rotateY(90deg);
    transition: opacity 0.4s ease-in-out 0.1s, transform 0.3s ease-in-out 0.1s;
  }
  .subMenu li:hover {
    background-color: #0562f9;
    color: #fff;
  }
  .down {
    margin-left: 8px;
  }
  .submenu_name.active {
    position: relative;
  }
  .submenu_name.active:hover .subMenu_right {
    display: block;
  }
  .subMenu_right {
    position: absolute;
    left: 140px;
    top: -10px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(168, 168, 168, 0.46);
    min-width: 140px;
    margin-left: -10px;
    display: none;
  }
  .subMenu_right li {
    margin-left: -20px;
    padding-left: 20px;
    text-align: center;
  }
  .right_phone {
    font-size: 24px;
    color: #050b20;
    width: 210px;
    height: 24px;
    /* margin-top: 46px; */
    /* position: absolute; */
    /*top: 0;
      bottom: 0;
      right: 0; */
    /* margin: auto 0; */
    margin-top: 44px;
  }
  .right_phone span {
    display: block;
  }
  .right_phone span img {
    padding-right: 8px;
    display: block;
    float: left;
    margin-top: -2px;
  }
  @media (max-width: 1440px) {
    .menu {
      margin-left: 30px;
      // padding-top: 46px;
    }
    .topnav {
      width: 1700px;
    }
    .left_logo {
      width: 295px;
    }
  }
  @media (max-width: 1900px) {
    .head_box {
      padding: 0;
      height: 80px;
      width: 100%;
      min-width: 100%;
      background: linear-gradient(
        180deg,
        rgba(135, 153, 253, 0.57),
        rgba(135, 122, 240, 0)
      );
      display: block;
    }
    .topnav {
      width: 100%;
    }
    .phone_bg {
      height: auto;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(135, 153, 253, 0.57),
        rgba(135, 122, 240, 0)
      );
    }
    .menu > ul > li {
      width: 120px;
    }
    .subMenu {
      width: 120px;
      left: -3px;
    }
    .menu > ul > li:hover::after {
      left: 0px;
    }
  }
  .fixed_bg {
    opacity: 0.75;
    background: #000000;
    border-radius: 10px;
    width: calc(100% - 30px);
    margin: 0 15px;
    position: absolute;
    color: #fff;
    z-index: 999999;
    left: 0;
    top: 100%;
    display: none;
  }
  .fixed_bg .el-menu {
    background-color: transparent !important;
  }
  .el-submenu__title {
    color: #fff !important;
    border-bottom: solid 1px #ccc;
  }
  .el-submenu:last-child .el-submenu__title {
    border-bottom: none;
  }
  .el-submenu__title:hover,
  .el-submenu__title:focus {
    background-color: transparent !important;
  }
  .el-menu {
    border-right: none !important;
  }
  .el-menu-item {
    // color: #fff !important;
  }

  .drawer-box ::v-deep .el-drawer {
    width: 60vw !important;
  }

  .drawer {
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 0 20px;
    line-height: 45px;

    .lv-2 {
      border-bottom: 1px solid #ebeef5;
      margin: 0 15px;
      font-size: 14px;
      line-height: 3em;
      padding: 0 10px;
    }
    ::v-deep .el-collapse {
      border: 0;
    }
    .lv-3 {
      padding: 0 20px;
    }
  }
  .mobile-nav {
    background: transparent url("~@/assets/images/nav-bg.png") no-repeat center
      top;
    background-size: 100% 78vw;
  }
  .hd_menu {
    display: none;
  }
  @media (max-width: 1900px) {
    .hd_menu {
      display: block;
    }
    .top_logo {
      display: none;
    }
  }
  @media (min-width: 1920px) {
    .top_logo {
      display: block;
    }
  }
</style>
