<template>
  <div id="app">
    <top-nav/>
    <router-view/>
    <About/>
  </div>
</template>

<script>
import About from './components/about.vue';
import topNav from './components/topnav'
export default {
  name: 'App',
  components: {
    topNav,
    About
  },
  methods: {
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
