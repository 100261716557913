import Vue from "vue"
import Router from "vue-router"
import store from "@/store"
Vue.use(Router)
const router = new Router({
  mode: "hash", // 去掉url中的#
  routes: [
    {
      path: "/",
      name: "首页",
      component: () => import("@/views/home.vue"),
      meta: {
        activeParentMenu: "首页",
        activeMenu: "首页",
        restore: true
      }
    },
    {
      path: "/collegeIntro",
      name: "学院概况",
      component: () => import("@/views/collegeIntro.vue"),
      meta: {
        activeParentMenu: "学院概况",
        activeMenu: "学院介绍",
        restore: true
      }
    },
    {
      path: "/collegeIntro",
      name: "学院介绍",
      component: () => import("@/views/collegeIntro.vue"),
      meta: {
        activeParentMenu: "学院概况",
        activeMenu: "学院介绍",
        restore: true
      }
    },
    {
      path: "/collegeIntro",
      name: "机构设置",
      component: () => import("@/views/collegeIntro.vue"),
      meta: {
        activeParentMenu: "学院概况",
        activeMenu: "机构设置",
        restore: true
      }
    },
    {
      path: "/collegeIntro",
      name: "校园资讯",
      component: () => import("@/views/collegeIntro.vue"),
      meta: {
        activeParentMenu: "学院概况",
        activeMenu: "校园资讯",
        restore: true
      }
    },
    {
      path: "/party",
      name: "党群工作",
      component: () => import("@/views/party/party.vue"),
      meta: {
        activeParentMenu: "党群工作",
        activeMenu: "学习园地",
        restore: true
      }
    },
    {
      path: "/supervision",
      name: "纪检监察",
      component: () => import("@/views/party/supervision.vue"),
      meta: {
        activeParentMenu: "党群工作",
        activeMenu: "纪检监察",
        restore: true
      }
    },
    // {
    //   path: "/supervision",
    //   name: "清廉旅院",
    //   component: () => import("@/views/party/supervision.vue"),
    //   meta: {
    //     activeParentMenu: "党群工作",
    //     activeMenu: "清廉旅院",
    //     restore: true
    //   }
    // },
    // {
    //     path: '/party/:id',
    //     name: 'partyDetail',
    //     component: () => import('@/views/partyDetail.vue')
    // }
    {
      path: "/exam",
      name: "招考信息",
      component: () => import("@/views/talent/exam.vue"),
      meta: {
        activeParentMenu: "招考信息",
        activeMenu: "招生计划",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "九大核心院系",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "九大核心院系",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "旅游产业发展学院",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "旅游产业发展学院",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "酒店管理",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "酒店管理",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "餐饮管理",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "餐饮管理",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "信息技术与艺术设计",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "信息技术与艺术设计",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "国际商务",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "国际商务",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "电子商务",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "电子商务",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "康养产业",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "康养产业",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "交通管理",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "交通管理",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "财经管理",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "财经管理",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "中职教育",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "中职教育",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "思想政治教育工作部",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "思想政治教育工作部",
        restore: true
      }
    },
    {
      path: "/subcollege",
      name: "商贸旅游产业发展",
      component: () => import("@/views/subCollege.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "商贸旅游产业发展",
        restore: true
      }
    },
    {
      path: "/education",
      name: "教育教学",
      component: () => import("@/views/science/education.vue"),
      meta: {
        activeParentMenu: "教育教学",
        activeMenu: "教学管理",
        restore: true
      }
    },
    // {
    //     path: '/education',
    //     name: '教育教学',
    //     component: () => import('@/views/science/education.vue'),
    //     meta:{
    //         activeParentMenu: '教育教学',
    //         activeMenu: '教学管理',
    //         restore: true
    //     }
    // },
    {
      path: "/education",
      name: "教学管理",
      component: () => import("@/views/science/education.vue"),
      meta: {
        activeParentMenu: "教育教学",
        activeMenu: "教学管理",
        restore: true
      }
    },
    {
      path: "/scientific",
      name: "督导科研",
      component: () => import("@/views/science/scientific.vue"),
      meta: {
        activeParentMenu: "教育教学",
        activeMenu: "督导科研",
        restore: true
      }
    },
    {
      path: "/course",
      name: "课程思政",
      component: () => import("@/views/science/course.vue"),
      meta: {
        activeParentMenu: "教育教学",
        activeMenu: "课程思政",
        restore: true
      }
    },
    {
      path: "/digitalLib",
      name: "数字图书馆",
      component: () => import("@/views/science/digitalLib.vue"),
      meta: {
        activeParentMenu: "教育教学",
        activeMenu: "数字图书馆",
        restore: true
      }
    },
    {
      path: "/educationManage",
      name: "教务管理平台",
      component: () => import("@/views/science/educationManage.vue"),
      meta: {
        activeParentMenu: "教育教学",
        activeMenu: "教务管理平台",
        restore: true
      }
    },
    {
      path: "/dHeight",
      name: "双高建设",
      component: () => import("@/views/science/dHeight.vue"),
      meta: {
        activeParentMenu: "双高建设",
        activeMenu: "双高建设",
        restore: true
      }
    },
    {
      path: "/support",
      name: "援疆行动",
      component: () => import("@/views/science/support.vue"),
      meta: {
        activeMenu: "援疆行动",
        restore: true
      }
    },
    {
      path: "/exam",
      name: "人才培养",
      component: () => import("@/views/talent/exam.vue"),
      meta: {
        activeParentMenu: "人才培养",
        activeMenu: "招考信息",
        restore: true
      }
    },
    {
      path: "/students",
      name: "学生工作",
      component: () => import("@/views/talent/students.vue"),
      meta: {
        activeMenu: "学生工作",
        restore: true
      }
    },
    {
      path: "/adultEducation",
      name: "继续教育",
      component: () => import("@/views/talent/adultEducation.vue"),
      meta: {
        // activeParentMenu: '人才培养',
        activeMenu: "继续教育",
        restore: true
      }
    },
    {
      path: "/international",
      name: "国际合作",
      component: () => import("@/views/cooperation/international.vue"),
      meta: {
        activeMenu: "国际合作",
        restore: true
      }
    },
    {
      path: "/union",
      name: "龙粤职教联盟",
      component: () => import("@/views/HGUnion.vue"),
      meta: {
        activeParentMenu: "龙粤职教联盟",
        activeMenu: "概括",
        restore: true
      }
    },
    {
      path: "/vocational",
      name: "职业技术",
      component: () => import("@/views/talent/vocational.vue"),
      meta: {
        // activeParentMenu: '首页',
        activeMenu: "职业技术",
        restore: true
      }
    },
    {
      path: "/countrySide",
      name: "乡村振兴",
      component: () => import("@/views/countrySide.vue"),
      meta: {
        activeParentMenu: "",
        activeMenu: "乡村振兴",
        restore: true
      }
    },
    {
      path: "/commonShow",
      name: "通用展示",
      component: () => import("@/views/commonMsg.vue")
    },
    {
      path: "/singleExam",
      name: "单考单招",
      component: () => import("@/views/talent/singleExam.vue"),
      meta: {
        activeParentMenu: "招考信息",
        activeMenu: "单考单招"
      }
    },
    {
      path: "/importantNews",
      name: "重要通知",
      component: () => import("@/views/talent/importantNews.vue"),
      meta: {
        activeParentMenu: "招考信息",
        activeMenu: "重要通知"
      }
    },
    {
      path: "/commonMore",
      name: "通用更多",
      component: () => import("@/views/commonMore.vue")
    },
    {
      path: "/commonMore",
      name: "合作伙伴",
      component: () => import("@/views/commonMore.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
// router.beforeEach((to, from, next) => {

//     // // 检查是否需要保存当前路由的信息
//     if (!from.meta.saved) {
//       // 保存当前路由的信息
//       router.app.$store.dispatch('saveLastRoute', {
//         activeParentMenu: from.meta.activeParentMenu,
//         activeMenu: from.meta.activeMenu
//       });
//       // 设置标志表示已保存
//       router.app.$store.dispatch('setRouteSaved', true);
//     }

//     // 检查是否需要恢复之前保存的路由信息
//     if (to.meta.restore) {
//       router.app.$store.dispatch('restoreLastRoute');
//     }

//     next();
//   });
let routerPush = Router.prototype.push
let routerReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err)
}

export default router
