<template>
    <div>
        <div class="bottom">
        <div class="about">
            <el-row>
                <el-col :span="4">
                    <div class="left">
                        <span class="title">关于我们</span>
                        <el-row>
                            <el-col :span="12" @click.native="handleClick('学院介绍')">
                                <div>校园概况</div>
                            </el-col>
                            <el-col :span="12" @click.native="handleClick('教育教学')">
                                <div>教育教学</div>
                            </el-col>
                            <el-col :span="12" @click.native="handleClick('招考信息')">
                                <div>人才培养</div>
                            </el-col>
                            <el-col :span="12" @click.native="handleClick('校企合作')">
                                <div>合作交流</div>
                            </el-col>
                            <el-col :span="12" @click.native="handleClick('党群工作')">
                                <div>政务服务</div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :span="8" :offset="3">
                    <div class="middle">
                        <span class="title">联系我们</span>
                        <el-row>
                            <el-col :span="2">
                                <img src="../assets/images/addr.png" alt=""/>
                            </el-col>
                            <el-col :span="20" align="left">
                                <div class="info">
                                    <h5>黑龙江旅游职业技术学院地址</h5>
                                    <h6>黑龙江省哈尔滨市南岗区学府路315号</h6>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="2"><img src="../assets/images/phone.png" alt=""></el-col>
                            <el-col :span="10" align="left">
                                <div class="info">
                                    <h5>联系电话</h5>
                                    <h6>0451-86684766</h6>
                                </div>
                            </el-col>
                            <el-col :span="10" align="left">
                                <div class="info">
                                    <h5>招生电话</h5>
                                    <h6>0451-86631888</h6>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :span="6" :offset="2">
                    <div class="right">
                        <el-row>
                            <el-col><img src="../assets/images/logo.png" alt=""></el-col>
                        </el-row>
                        <el-row style="margin-top: 10px;">
                            <el-col :span="12"><img src="../assets/images/qrcode1.png" alt=""></el-col>
                            <el-col :span="12"><img src="../assets/images/qrcode1.png" alt=""></el-col>
                            <el-col :span="24"><span>扫描微信二维码，了解更多</span></el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="link">
            <ul>
                <li>
                    <span>友情链接</span>
                    <a href="https://sswt.hlj.gov.cn/" target="_blank">黑龙江省商务厅 丨</a>
                    <a href="http://jyt.hlj.gov.cn/" target="_blank">黑龙江省教育厅 丨</a>
                    <a href="http://www.lzk.hl.cn/" target="_blank">黑龙江省招生考试信息港 丨</a>
                    <a href="http://www.gdit.edu.cn/" target="_blank">广东科学技术职业学院</a>
                </li>
            </ul>
            <p>
                <img src="../assets/images/link_icon.png" alt="">
                <span>黑龙江旅游职业技术学院- 黑ICP备2022006900号-1</span>
            </p>
        </div>
        </div>
        <div class="phone_about_box">
            <div class="phone_bottom">
                <el-row>
                    <el-col class="title" :span="24"><div>关于我们</div></el-col>
                    <el-col :span="6" @click.native="handleClick('学院介绍')">
                        <div>校园概况</div>
                    </el-col>
                    <el-col :span="6" @click.native="handleClick('教育教学')">
                        <div>科学研究</div>
                    </el-col>
                    <el-col :span="6" @click.native="handleClick('招考信息')">
                        <div>人才培养</div>
                    </el-col>
                    <el-col :span="6" @click.native="handleClick('校企合作')">
                        <div>合作交流</div>
                    </el-col>
                    <el-col :span="6" @click.native="handleClick('党群工作')">
                        <div>政务服务</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col class="title" :span="24">联系我们</el-col>
                    <el-row>
                        <el-col :span="2"><img src="@/assets/images/addr.png" alt="" width="20px" height="20px"></el-col>
                        <el-col :span="20">
                            <span>黑龙江旅游职业技术学院地址</span>
                            <p>黑龙江省哈尔滨市南岗区学府路315号</p>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2"><img src="@/assets/images/phone.png" alt="" width="16px" height="22px"></el-col>
                        <el-col :span="20">
                            <el-row>
                                <el-col :span="24">
                                    <span>联系电话</span>
                                    <p>0451-86684766</p>
                                </el-col>
                                <el-col :span="24">
                                    <span>招生电话</span>
                                    <p>0451-86631888</p>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-row>
                <el-row class="about_link">
                    <el-col class="title" :span="24">友情链接</el-col>
                    <el-col :span="11"><a href="https://sswt.hlj.gov.cn/" target="_blank">黑龙江省商务厅</a></el-col>
                    <el-col :span="11"><a href="http://jyt.hlj.gov.cn/" target="_blank">黑龙江省教育厅</a></el-col>
                    <el-col :span="11"><a href="http://www.lzk.hl.cn/" target="_blank">黑龙江省招生考试信息港</a></el-col>
                    <el-col :span="11"><a href="http://www.gdit.edu.cn/" target="_blank">广东科学技术职业学院</a></el-col>
                </el-row>
                <el-row class="qr_code">
                    <el-col :span="24">
                        <img src="@/assets/images/logo.png" alt="" width="182px" height="37px">
                    </el-col>
                    <el-col class="code_img">
                        <img src="@/assets/images/qrcode1.png" alt="">
                        <img src="@/assets/images/qrcode1.png" alt="">
                    </el-col>
                    <el-col class="qr_title">扫描微信二维码，了解更多</el-col>
                    <p><img src="@/assets/images/link_icon.png" alt="" width="20px" height="20px" style="vertical-align: sub; margin-bottom: -3px;margin-right: 6px">黑龙江旅游职业技术学院- 黑ICP备2022006900号-1</p>
                </el-row>
            </div>
        </div>  
        
    </div>
    
</template>

<script>
export default {
    name: "about",
    data() {
        return {
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    methods: {
        handleClick(name) {
            if(name === '校企合作'){
                window.open("https://ljlyzy.gxbys.org.cn/", "_blank")
                this.$router.go(0)
                return
            }
            this.$router.push({
                name: name
            });
        },
    }

}
</script>

<style scoped lang="scss">
@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
div , a{
    text-decoration: none;
    color: #A0A0A0;
}
.bottom{
    display: block;
    width: calc(100% - 40px);
    margin: 0 auto;
    border-top: solid 1px #E0E0E0;
}
.about{
    width: 1400px;
    margin: 0 auto;
    padding: 30px 0;
}
.about .title{
    font-size: 22px;
    color: #333333;
    display: block;
    height: 60px;
    vertical-align: middle;
    text-align: left;
}
.about .left{
    animation: fadeInFromBottom 1.5s ease-in-out;
}
.about .left .el-col{
    text-align: left;
    margin-bottom: 50px;
}
.about .left div{
    font-size: 20px;
    cursor: pointer;
}
.middle{
    animation: fadeInFromBottom 1.5s ease-in-out;
}
.middle .el-row{
    padding-bottom: 30px;
}
.middle h5, .middle h6{
    padding: 0;
    margin: 0;
    font-size: 22px;
    color: #A0A0A0;
    font-weight: normal;
}
.middle h5{
    
}
.middle h6{
    color: #333333;
}
.middle .info{
    margin-left: 22px;
    line-height: 42px;
}
.right{
    animation: fadeInFromBottom 1.5s ease-in-out;
}
.right span{
    color: #333333;
    font-size: 22px;
}
.link{
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;
    border-top: solid 1px #E0E0E0;
}
.link ul li>span{
    font-size: 18px;
    color: #505050;
    font-weight: 600;
    margin-right: 20px;
}
.link ul{
    margin: 0;
    padding: 0;
}
.link ul li{
    list-style: none;
    display: inline-block;
    margin-right: 20px;
}
.link ul li a{
    font-size: 18px;
    color: #505050;
}
.link p{
    font-size: 20px;
}
.link p img{
    vertical-align: middle;
    margin-right: 10px;
}
.link p span{
    color: #A0A0A0;
}
.phone_about_box{
    display: none;
    margin-top: 20px;
    padding: 0 15px;
    animation: fadeInFromBottom 1.5s ease-in-out;
}
.phone_bottom{
    font-size: 18px;
    color: #A0A0A0;
    width: calc(100% - 30px);
    height: auto;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.17); 
    padding: 15px;
}
.phone_bottom > .el-row{
    border-bottom: solid 1px #E8E8E8;
    margin: 15px 0;
}
.phone_bottom > .el-row:first-child{
    margin: 0;
}
.phone_bottom > .el-row:last-child{
    border-bottom: none;
    margin-bottom: 0;
}
.phone_bottom > .el-row > .el-col{
    margin: 10px 0;
}
.phone_bottom > .el-row > .el-col.title{
    margin-top: 0;
    color: #000;
}
.phone_bottom .el-col p{
    font-size: 16px;
    color: #000;
}
.phone_bottom .about_link >.el-col:nth-child(2),.phone_bottom .about_link >.el-col:nth-child(4){
    border-right: solid 1px #a0a0a0;
    margin-right: 15px;
}
.phone_bottom .qr_code{
    border-top: none;
}
.phone_bottom .qr_code .el-col{
    text-align: center;
}
.phone_bottom .qr_code .code_img{
    margin: 0;
}
.phone_bottom .qr_code .code_img img{
    width: 86px;
    height: 86px;
    margin-right: 10px;
}
.phone_bottom .qr_code .code_img img:last-child{
    margin-right: 0;
}
.phone_bottom .qr_code .qr_title{
    font-size: 14px;
    color: #333333;
    margin-top: 0;
    margin-bottom: 40px;
}
.phone_bottom .qr_code p{
    text-align: center;
    clear: both;
    margin:0
}
@media (max-width: 1400px) {
    .about{
        width: 100%;
    }
    
}
@media (max-width: 768px) {
    .phone_bottom{
        font-size: 12px;
        .el-col {
            p{
                font-size: 14px;
            }
        }
    }
    .bottom{
        display: none;
    }
    .phone_about_box{
        display: block;
    }
}

</style>