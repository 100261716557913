import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "vuex"
})
const store = new Vuex.Store({
  state: {
    activeMenu: "", // 当前激活的菜单
    activeParentMenu: "", //当前激活菜单的父级菜单
    dicIds: [], //双高建设id
    commonMoreName: null, //查看更多标题
    commonItem: null,
    collegeId: null,
    collegeDicType: null,
    topNav: [],
    breadcrumbs: [],
    apiUrl: ""
    // lastActiveParentMenu: '',
    // lastActiveMenu: '',
    // routeSaved: false
  },
  mutations: {
    SET_DIC_IDS(state, dicIds) {
      state.dicIds = dicIds
    },
    SET_COMMON_MORE_NAME(state, name) {
      state.commonMoreName = name
    },
    SET_COMMON_ITEM(state, item) {
      state.commonItem = item
    },
    SET_COLLEGE_ID(state, collegeId) {
      state.collegeId = collegeId
    },
    SET_DIC_TYPE(state, collegeDicType) {
      state.collegeDicType = collegeDicType
    },
    SET_TOPNAV(state, topNav) {
      state.topNav = topNav
    },
    // SET_BREADCRUMBS(state,breadcrumbs){
    //     state.breadcrumbs = breadcrumbs;
    // }
    SAVE_LAST_ROUTE(state, payload) {
      state.lastRoute = payload
    },
    RESTORE_LAST_ROUTE(state) {
      console.log("🚀 ~ RESTORE_LAST_ROUTE ~ state:", state)
      state.activeParentMenu = state.lastActiveParentMenu
      state.activeMenu = state.lastActiveMenu
    },
    SET_ROUTE_SAVED(state, value) {
      state.routeSaved = value
    },
    SET_ACTIVE_MENU(state, payload) {
      console.log("🚀 ~ SET_ACTIVE_MENU ~ SET_ACTIVE_MENU:", state)
      state.activeParentMenu = payload.activeParentMenu
      state.activeMenu = payload.activeMenu
    },
    updateBreadcrumbs(state, payload) {
      state.breadcrumbs = payload
    },
    setNavData(state, data) {
      state.activeMenu = data
    },
    SET_API_URL(state, apiUrl) {
      state.apiUrl = apiUrl
    }
  },
  actions: {
    setCommonMoreName({ commit }, name) {
      commit("SET_COMMON_MORE_NAME", name)
    },
    setCommonItem({ commit }, item) {
      commit("SET_COMMON_ITEM", item)
    },
    setCollegeId({ commit }, collegeId) {
      commit("SET_COLLEGE_ID", collegeId)
    },
    setDicType({ commit }, collegeDicType) {
      commit("SET_DIC_TYPE", collegeDicType)
    },
    setBannerMsg({ state }, routeName) {
      return new Promise((resolve, reject) => {
        let ss = state.topNav.filter((item) => item.menuName === routeName)
        resolve(ss[0])
      })
    },
    saveLastRoute({ commit }, payload) {
      commit("SAVE_LAST_ROUTE", payload)
    },
    restoreLastRoute({ commit }) {
      commit("RESTORE_LAST_ROUTE")
    },
    setRouteSaved({ commit }, value) {
      commit("SET_ROUTE_SAVED", value)
    },
    setActiveMenu({ commit }, payload) {
      commit("SET_ACTIVE_MENU", payload)
    }
  },
  getters: {
    activeMenu: (state) => state.activeMenu,
    activeParentMenu: (state) => state.activeParentMenu,
    getDicById: (state) => (label) => {
      return state.dicIds.find((item) => item.label === label)?.id
    },
    getDicIds: (state) => state.dicIds,
    commonMoreName: (state) => state.commonMoreName,
    commonItem: (state) => state.commonItem,
    collegeId: (state) => state.collegeId,
    collegeDicType: (state) => state.collegeDicType
  },

  plugins: [vuexLocal.plugin]
})

export default store
